import React ,{useState,useRef} from "react";
import { Link } from "react-router-dom";
import { logout } from "./redux/actions";
import { useDispatch,useSelector } from "react-redux";
import classNames from 'classnames';
import { InputText } from "primereact/inputtext";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { CustomerService } from "./service/CustomerService";
export const AppTopbar = (props) => {
    const dispatch = useDispatch();
    const handelLogout = () => {
        dispatch(logout());
    };
    const toast = useRef(null);
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const [passwordDialog, setPasswordDialog] = useState(false);
    const [passsubmitted,setPassSubmitted] = useState(false);
    const [oldPassword, setOldPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const accept = () => {
        setDisplayConfirmation(false)
        handelLogout();
    };
    const onOldPassChange = (e) => {
        const val = (e.target && e.target.value) || "";
        setOldPassword(val);
    };
    const onNewPassChange = (e) => {
        const val = (e.target && e.target.value) || "";
        setNewPassword(val);
    };
    const onConfirmPassChange = (e) => {
        const val = (e.target && e.target.value) || "";
        setConfirmPassword(val);
    };
   
    const hidePassDialog= () => {
        setPassSubmitted(false);
        setPasswordDialog(false);
    };
    const password_validate= (p) => {
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

       return strongRegex.test(p) ;
        
    }
    const savePassProduct = () => {
        setPassSubmitted(true);
        // console.log(Value8)
        if (!oldPassword) {
            toast.current.show({ severity: "error", summary: "Old Password.", detail: "Old Password can't be empty.", life: 3000 });
        }
        if (!newPassword) {
            toast.current.show({ severity: "error", summary: "New Password.", detail: "New Password can't be empty.", life: 3000 });
        }
        if (confirmPassword !== newPassword) {
            toast.current.show({ severity: "error", summary: "Confirm Password.", detail: "Confirm Password is not same.", life: 3000 });
        }
        if(!password_validate(newPassword)){
            toast.current.show({ severity: "error", summary: "New Password.", detail: "Must contain at least 1 lowercase , at least 1 uppercase, at least 1 numeric, at least one special character and must be eight characters or longer", life: 10000 });
        }
        else{
         const customerService = new CustomerService();
        let tableItem = {};
        tableItem.oldPassword = oldPassword;
        tableItem.newPassword = newPassword;
        tableItem.confirmPassword = confirmPassword;
        tableItem.token = user.token;
        tableItem.id = user.user.id;

        customerService.getpasschange(tableItem).then((data) => {
            toast.current.show({ severity: "success", summary: "Successful", detail: "User Updated", life: 3000 });
        }).catch((error) => {
            toast.current.show({ severity: "error", summary: "Failed", detail: "Something went wrong!", life: 3000 });
        });
        setPassSubmitted(false);
        setPasswordDialog(false);
    }
    }
    const passwordDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hidePassDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={savePassProduct} />
        </>
    );
    const reject = () => {
        setDisplayConfirmation(false)
        // toast.current.show({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 5000 });
    };
    const confirmationDialogFooter = (
        <>
            <Button type="button" label="No" icon="pi pi-times" onClick={() => reject()} className="p-button-text" />
            <Button type="button" label="Yes" icon="pi pi-check" onClick={() => accept()} className="p-button-text" autoFocus />
        </>
    );
    
    const styleObj = {
        opacity: 0
    }
    return (
        <div className="layout-topbar" style={ !isLoggedIn ? styleObj : {}}>
             <Toast ref={toast} />
                        <Dialog header="Confirmation" visible={displayConfirmation} onHide={() => setDisplayConfirmation(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooter}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                <span>Are you sure you want to proceed?</span>
                            </div>
                        </Dialog>
                        <Dialog visible={passwordDialog} style={{ width: "450px" }} header="Change Password" modal className="p-fluid" footer={passwordDialogFooter} onHide={hidePassDialog}>
                        
                        <div className="field">
                            <label htmlFor="name">Old Password</label>
                            <InputText id="name" value={oldPassword} onChange={(e) => onOldPassChange(e)} required autoFocus className={classNames({ "p-invalid": passsubmitted && !oldPassword })} />
                            {passsubmitted && !oldPassword && <small className="p-invalid">Password is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">New Password</label>
                            <InputText id="name" value={newPassword} onChange={(e) => onNewPassChange(e)} required autoFocus className={classNames({ "p-invalid": passsubmitted && !newPassword })} />
                            {passsubmitted && !newPassword && <small className="p-invalid">Password is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Confirm Password</label>
                            <InputText id="name" value={confirmPassword} onChange={(e) => onConfirmPassChange(e)} required autoFocus className={classNames({ "p-invalid": passsubmitted && ( confirmPassword !== newPassword) })} />
                            {passsubmitted && ( confirmPassword !== newPassword)  && <small className="p-invalid">Confim Password is not same.</small>}
                        </div>
                    </Dialog>
           
            <Link to="/" className="layout-topbar-logo">
                <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/white-logo.png' : 'assets/layout/images/white-logo.png'} alt="logo"/>
                <span>DealzArabia</span>
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>
            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

       
            
         
                <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={() => setDisplayConfirmation(true)} >
                            <i className="pi pi-fw pi-sign-out"/>
                            <span>Logout</span>
                        </button>
                    </li>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={() => setPasswordDialog(true)}>
                            <i className="pi pi-cog"/>
                            <span>Settings</span>
                        </button>
                        
                    </li>
                    {/*<li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-user"/>
                            <span>Profile</span>
                        </button>
                    </li>*/}
                </ul> 
        </div>
    );
};
