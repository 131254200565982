import { LOGIN_PARTIAL_SUCCESS, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from "../actions/types";

const initialState = {
    isLoggedIn: false,
    redirect :0,
    sentotp:false,
    user: null,
    token: null,
};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LOGIN_PARTIAL_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
                sentotp:true,
                redirect :1,
                user: payload.user,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                sentotp:false,
                token: payload.token,
                redirect :1,
                user: payload.user,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                sentotp:false,
                user: null,
                redirect :0,
                token: null,
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                redirect :0,
                token: null,
            };
        default:
            return state;
    }
}