import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { RadioButton } from "primereact/radiobutton";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { CustomerService } from "../service/CustomerService";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";

export const Users = () => {
    let emptyProduct = {
        username: "",
        password: "",
        email: "",
        id: "",
        user_id: "",
        role: "",
        balance: "0",
       status:"0",
       allowed_recharges:"1",
    };

    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const [products, setProducts] = useState(null);
    const [oldPassword, setOldPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [Value8, setValue8] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [passwordDialog, setPasswordDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [loading1, setLoading1] = useState(true);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [passsubmitted,setPassSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const statuses = ["0", "1"];
    const [radioValue, setRadioValue] = useState(1);
    const [RoleValue, setRoleValue] = useState(2);
    useEffect(() => {
        const customerService = new CustomerService();

        setLoading1(true);
        if (user) {
            let tableItem = {};
            tableItem.token = user.token;
            tableItem.id = user.user.id;
            tableItem.role = user.user.role;
            customerService.getUsers(tableItem).then((data) => {
                if (isIterable(data)) {
                    
                setProducts(data);
                setLoading1(false);
                }
            });
        }
    }, [user]);
    const isIterable= (obj) => {
        // checks for null and undefined
        if (obj == null) {
          return false;
        }
        return typeof obj[Symbol.iterator] === 'function';
      }
    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    };

    const hideDialog = () => {
        setRoleValue(2);
        setSubmitted(false);
        setProductDialog(false);
    };
    const hidePassDialog= () => {
        setPassSubmitted(false);
        setPasswordDialog(false);
    };
    const password_validate= (p) => {
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

       return strongRegex.test(p) ;
        
    }
    const savePassProduct = () => {
        setPassSubmitted(true);
        // console.log(Value8)
        // if (!oldPassword) {
        //     toast.current.show({ severity: "error", summary: "Old Password.", detail: "Old Password can't be empty.", life: 3000 });
        // }
        if (!newPassword) {
            toast.current.show({ severity: "error", summary: "New Password.", detail: "New Password can't be empty.", life: 3000 });
        }
        if (confirmPassword !== newPassword) {
            toast.current.show({ severity: "error", summary: "Confirm Password.", detail: "Confirm Password is not same.", life: 3000 });
        }
        if(!password_validate(newPassword)){
            toast.current.show({ severity: "error", summary: "New Password.", detail: "Must contain at least 1 lowercase , at least 1 uppercase, at least 1 numeric, at least one special character and must be eight characters or longer", life: 10000 });
        }
        else{
         const customerService = new CustomerService();
        let tableItem = {};
        // tableItem.oldPassword = oldPassword;
        tableItem.oldPassword = newPassword;
        tableItem.newPassword = newPassword;
        tableItem.confirmPassword = confirmPassword;
        tableItem.token = user.token;
        tableItem.id = Value8.id;
        tableItem.role = user.user.role;

        customerService.getpasschange(tableItem).then((data) => {
            toast.current.show({ severity: "success", summary: "Successful", detail: "User Updated", life: 3000 });
        }).catch((error) => {
            toast.current.show({ severity: "error", summary: "Failed", detail: "Something went wrong!", life: 3000 });
        });
        setPassSubmitted(false);
        setPasswordDialog(false);
    }
    }
    const saveProduct = () => {
        setSubmitted(true);
        // if (!value8.id) {
        //     toast.current.show({ severity: "error", summary: "Please select customer.", detail: "Customer can't be empty.", life: 3000 });
        // }
        const customerService = new CustomerService();

        if (product.username.trim()) {
            let _products = [...products];

            let _product = { ...product };
            if (product.id) {
                const index = findIndexById(product.id);
                _product.role = RoleValue;
                _product.status = radioValue;
                // _product.allowed_recharges = AllowedValue;
                _products[index] = _product;

                let tableItem = {};
                tableItem = _product;
                tableItem.token = user.token;

                // console.log(tableItem);
                customerService.getedituserinfo(tableItem).then((data) => {
                    // console.log(data);
                    toast.current.show({ severity: "success", summary: "Successful", detail: "User Updated", life: 3000 });
                }).catch((error) => {
                    toast.current.show({ severity: "error", summary: "Failed", detail: "Something went wrong!", life: 3000 });
                });
                
                
            } else {
                
                // _product.id = _product.id;
                _product.role = RoleValue;
                _product.status = radioValue;
                // _product.allowed_recharges = AllowedValue;
                let tableItem = {};
                tableItem = _product;
                tableItem.token = user.token;
                
                customerService.getedituserinfo(tableItem).then((data) => {
                    // console.log(data)
                    if(!data.username)
                    {
                        toast.current.show({ severity: "error", summary: data.msg, detail: "", life: 5000 });
                    }else{
                        _products.push(data);
                        toast.current.show({ severity: "success", summary: "Successful", detail: "User Created", life: 3000 });
                    
                    }
                    }).catch((error) => {
                    toast.current.show({ severity: "error", summary: "Failed", detail: "Something went wrong!", life: 3000 });
                });
              
            }
            setRoleValue(2);
            setProducts(_products);
            setProductDialog(false);
            setProduct(emptyProduct);
        }
    };

    const editProduct = (rowdata) => {
     
        for (let index of products) {
            if (index.id === rowdata.id) {
                setValue8(index);
            }
        }
        setRadioValue(rowdata.status);
        setRoleValue(rowdata.role);
        // setAllowedValue(rowdata.allowed_recharges);
        setProduct({ ...rowdata });
        setProductDialog(true);
    };

    const  ChangePassword= (rowdata) => {
        for (let index of products) {
            if (index.id === rowdata.id) {
                setValue8(index);
            }
        }
        setPasswordDialog(true);
    };


    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < products.length; i++) {
            if (products[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

   

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    };

    const onOldPassChange = (e) => {
        const val = (e.target && e.target.value) || "";
        setOldPassword(val);
    };
    const onNewPassChange = (e) => {
        const val = (e.target && e.target.value) || "";
        setNewPassword(val);
    };
    const onConfirmPassChange = (e) => {
        const val = (e.target && e.target.value) || "";
        setConfirmPassword(val);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        );
    };

    const statusBodyTemplate = (rowData) => {
     
        if (rowData.status === 0) {
            return <span className={`product-badge status-outofstock`}>In-Active</span>;
        } else {
            return <span className={`product-badge status-instock`}>Active</span>;
        }
    };
    const roleBodyTemplate= (rowData) => {
  
        if (rowData.role === 2) {
            return <span className={`product-badge status-outofstock`}>User</span>;
        } else {
            return <span className={`product-badge status-instock`}>Admin</span>;
        }
    };
    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;
    };

    const statusItemTemplate = (option) => {
        if (option === "0") {
            return <span className={`product-badge status-outofstock`}>In-Active</span>;
        } else {
            return <span className={`product-badge status-instock`}>Active</span>;
        }
    };
   

    
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-eye" className="p-button-rounded p-button-warning" onClick={() => ChangePassword(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Users</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const passwordDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hidePassDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={savePassProduct} />
        </>
    );


    if (!isLoggedIn) {
        return <Redirect to="/" />;
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Users Info</h5>
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

                    <DataTable
                        value={products}
                        loading={loading1}
                        selection={selectedProducts}
                        onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                        globalFilter={globalFilter}
                        emptyMessage="No products found."
                        header={header}
                    >
                        <Column field="username" header="User" sortable></Column>
                        {/* <Column field="email" header="Email" sortable></Column> */}
                        <Column field="role" header="Role" body={roleBodyTemplate} sortable></Column>
                        {/* <Column header="Balance" sortable filterField="balance" dataType="numeric" body={balanceBodyTemplate} filter filterElement={balanceFilterTemplate}></Column> */}
                        {/* <Column field="urls" header="Url" sortable></Column> */}
                        {/* <Column field="user_id" header="Assign" body={assignBodyTemplate} sortable></Column> */}
                        <Column field="status" header="Status" body={statusBodyTemplate} dataType="numeric"sortable filter filterElement={statusFilterTemplate}></Column>
                        {/* <Column field="allowed_recharges" header="Operator" dataType="numeric" body={allowrechargesBodyTemplate} sortable filter filterElement={allowrechargesFilterTemplate}></Column> */}
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={productDialog} style={{ width: "450px" }} header="User Info" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={product.username} onChange={(e) => onInputChange(e, "username")} required autoFocus className={classNames({ "p-invalid": submitted && !product.username })} />
                            {submitted && !product.username && <small className="p-invalid">Name is required.</small>}
                        </div>
                        {!product.id && <div className="field">
                            <label htmlFor="name">Password</label>
                            <InputText id="name" value={product.password} onChange={(e) => onInputChange(e, "password")} required autoFocus className={classNames({ "p-invalid": submitted && !product.password })} />
                            {submitted && !product.password && <small className="p-invalid">Password is required.</small>}
                        </div>}
                        {/* {!product.id && <div className="field">
                            <label htmlFor="Email">Email</label>
                            <InputText id="Email"  type="email" value={product.email} onChange={(e) => onInputChange(e, "email")} required autoFocus className={classNames({ "p-invalid": submitted && !product.email })} />
                            {submitted && !product.email && <small className="p-invalid">Email is required.</small>}
                        </div>} */}
                        <div className="formgrid grid">
                            <label>Role  :-</label>
                            <div className="col-12 md:col-4">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option1" name="option" value="1" checked={RoleValue === 1} onChange={(e) => setRoleValue(parseInt(e.value))} />
                                    <label htmlFor="option1">Admin</label>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option2" name="option" value="2" checked={RoleValue === 2} onChange={(e) => setRoleValue(parseInt(e.value))} />
                                    <label htmlFor="option2">User</label>
                                </div>
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <label>Status</label>
                            <div className="col-12 md:col-4">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option3" name="option" value="0" checked={radioValue === 0} onChange={(e) => setRadioValue(parseInt(e.value))} />
                                    <label htmlFor="option3">In-active</label>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="field-radiobutton">
                                    <RadioButton inputId="option4" name="option" value="1" checked={radioValue === 1} onChange={(e) => setRadioValue(parseInt(e.value))} />
                                    <label htmlFor="option4">Active</label>
                                </div>
                            </div>
                        </div>
                        
                    </Dialog>
                    <Dialog visible={passwordDialog} style={{ width: "450px" }} header="Change Password" modal className="p-fluid" footer={passwordDialogFooter} onHide={hidePassDialog}>
                        
                        {/* <div className="field">
                            <label htmlFor="name">Old Password</label>
                            <InputText id="name" value={oldPassword} onChange={(e) => onOldPassChange(e)} required autoFocus className={classNames({ "p-invalid": passsubmitted && !oldPassword })} />
                            {passsubmitted && !oldPassword && <small className="p-invalid">Password is required.</small>}
                        </div> */}
                        <div className="field">
                            <label htmlFor="name">New Password</label>
                            <InputText id="name" value={newPassword} onChange={(e) => onNewPassChange(e)} required autoFocus className={classNames({ "p-invalid": passsubmitted && !newPassword })} />
                            {passsubmitted && !newPassword && <small className="p-invalid">Password is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Confirm Password</label>
                            <InputText id="name" value={confirmPassword} onChange={(e) => onConfirmPassChange(e)} required autoFocus className={classNames({ "p-invalid": passsubmitted && ( confirmPassword !== newPassword) })} />
                            {passsubmitted && ( confirmPassword !== newPassword)  && <small className="p-invalid">Confim Password is not same.</small>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};
