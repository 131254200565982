import React, {useState, useEffect, useRef  } from "react";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from 'primereact/inputtext';
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from 'primereact/button';
import { CustomerService } from "../service/CustomerService";
import { Dialog } from 'primereact/dialog';

export const Home = () => {
    
    // const height_val = "150"
    const [trans, setTrans] = useState(null);
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const [number, setNumber] = useState("");
    const [rowData, setrowData] = useState("");
    const toast = useRef(null);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    

    const reject = () => {
        setDisplayConfirmation(false)
        // toast.current.show({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 5000 });
    };
    const confirmationDialogFooter = (
        <>
            <Button type="button" label="No" icon="pi pi-times" onClick={() => reject()} className="p-button-text" />
            <Button type="button" label="Yes" icon="pi pi-check" onClick={() => accept()} className="p-button-text" autoFocus />
        </>
    );
    const customerService = new CustomerService();
    useEffect(() => {
        
        
    }, []);
    const editProduct = (rowData) => {
        setDisplayConfirmation(true)
        setrowData(rowData);
    };
    const searchnewlist = () => {
        if (user) {
            if(!number){
                 toast.current.show({ severity: "error", summary: "Search Field Can't Be Empty !", detail: "", life: 5000 });
                return false;
            }

           
            let tableItem = {};
            tableItem.token = user.token;
            tableItem.role = user.user.role;
            tableItem.id = user.user.id;
            tableItem.number = number;
            
            customerService.getSearchCode(tableItem).then((data) => {
                setTrans(data);
                
            });

            
        }
    };
    const onNumberChange = (e) => {
        setNumber(e.target.value);
    };
    const accept = () => {
        setDisplayConfirmation(false)
        let tableItem = {};
        tableItem.token = user.token;
        tableItem.role = user.user.role;
        tableItem.id = user.user.id;
        tableItem.products_id = rowData.id;
        tableItem.voucher_id = rowData.code;
            
        if (rowData.status === 0) {
            tableItem.status = 1;
        }
        else{
            tableItem.status = 0;
        }
        customerService.getChangeStatus(tableItem).then((data) => {
           if(data.status === "0")
           {
            toast.current.show({ severity: 'error', summary: 'Failed', detail: data.message, life: 5000 });
           }
           else{
            toast.current.show({ severity: "success", summary: "Success", detail: data.message, life: 5000 });
            
           }
           searchnewlist();
        });
    };
   
    const statusBodyTemplate = (rowData) => {
        if (rowData.status === 0) {
            return (
                 <span className={`text-orange-500 font-medium`}>
                In-Active </span>
            );
        } else  {
            return (
                <span  className={`customer-badge status-qualified`}>
                    
                    <Button type="button" label="Active" className="mr-2 p-button-success" onClick={() => editProduct(rowData)}></Button>
                </span>
                
            );
        } 
    };
    const NameBodyTemplate = (rowData) => {

            return rowData.first_name + " " + rowData.last_name;
    };
    if (!isLoggedIn) {
        return <Redirect to="/login" />;
    }
    return (
        <div className="grid">
             <Toast ref={toast} />
             <Dialog header="Confirmation" visible={displayConfirmation} onHide={() => setDisplayConfirmation(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooter}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                <span>Are you sure you want to Change Status?</span>
                            </div>
                        </Dialog>
            <div className="col-12 lg:col-12 xl:col-12">
                <div className="card mb-0">
                <div className="grid p-fluid">
                        

                        

                        <div className="col-12 md:col-12">
                            <div className="p-inputgroup">
                                
                                <InputText id="number" value={number} onChange={(e) => onNumberChange(e)} type="text"  required placeholder="Coupons" />
                                <Button  type="button" label="Search"  onClick={searchnewlist}/>
                            </div>
                        </div>

                        
                    </div>
                    
               
                </div>
            </div>
            
            <div className="col-12 xl:col-12">
                <div className="card" >
                    <h5>List of Coupons</h5>
                    <DataTable value={trans} className="p-datatable-gridlines datatable-responsive"  rows={5}>
                        <Column field="first_name" header="Name" body={NameBodyTemplate}  />
                        <Column field="code" header="Code" sortable />
                        <Column field="amount" header="Amount" sortable />
                        <Column field="status" header="Status" body={statusBodyTemplate} sortable />
                    </DataTable>
                </div>
            </div> 
        </div>
    );
};
