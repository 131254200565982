import axios from "axios";

export class CustomerService {
    getCustomersMedium() {
        return axios.get("assets/demo/data/customers-medium.json").then((res) => res.data.data);
    }

    getCustomersLarge() {
        return axios.get("assets/demo/data/customers-large.json").then((res) => res.data.data);
    }
    getHistory(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};
        if (tableItem instanceof FormData) {
            options = {
                url: `https://paykart2.ddns.net:8888/api/v1/RechargeHistory`,
                method: httpmethod,
                data: tableItem,
                headers: { "content-type": "multipart/form-data;" },
            };
        } else {
            options = {
                url: `https://paykart2.ddns.net:8888/api/v1/RechargeHistory`,
                method: httpmethod,
                data: tableItem,
            };
        }
        return axios(options)
            .then((response) => {
                return response.data.info[0];
            })
            .catch((error) => {
                return error;
            });
    }

    CreateLuckyDraw(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/create_lucky_draw.php`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    getChangeStatus(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/change-status.php`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    getChangeStatusDealz(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/change-status-deal.php`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    getcollectionStatus(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/change-collection-status.php`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    getChangeOrderStatus(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/change-status-order.php`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    getSellerType(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/get-seller-type.php`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    getSellerTypeName(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/get-seller-type-name.php`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    getCampaign(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/get-Campaign.php`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }

    getDeleteStatus(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/change-delete.php`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    getDeleteStatusDealz(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/change-delete-deal.php`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    settledall(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/send-settled-all.php`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    sendSmSall(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/send-sms-all.php`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    sendEmailall(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/send-email-all.php`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    setwinnerDeleteall(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/change-winner-delete-all.php`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }

    setDeleteall(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/change-delete-all.php`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    getSearchCode(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};
        if (tableItem instanceof FormData) {
            options = {
                url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/search-code.php`,
                method: httpmethod,
                data: tableItem,
                headers: { "content-type": "multipart/form-data;" },
            };
        } else {
            options = {
                url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/search-code.php`,
                method: httpmethod,
                data: tableItem,
            };
        }
        return axios(options)
            .then((response) => {
                if (response.data.status !== 1) {
                    return [];
                } else {
                    return response.data.info;
                }

            })
            .catch((error) => {
                return error;
            });
    }

    getSearchCollectionCode(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};
        if (tableItem instanceof FormData) {
            options = {
                url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/search-collection-code.php`,
                method: httpmethod,
                data: tableItem,
                headers: { "content-type": "multipart/form-data;" },
            };
        } else {
            options = {
                url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/search-collection-code.php`,
                method: httpmethod,
                data: tableItem,
            };
        }
        return axios(options)
            .then((response) => {
                if (response.data.status !== 1) {
                    return [];
                } else {
                    return response.data.info;
                }

            })
            .catch((error) => {
                return error;
            });
    }
    getTransferHistory(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: `https://paykart2.ddns.net:8888/api/v1/TransferHistory`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info[0];
            })
            .catch((error) => {
                return error;
            });
    }

    saveamzvoucher(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://paykart2.ddns.net:8888/api/v1/add_all_amzvouchers`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    getAmzVoucherHistory(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: `https://paykart2.ddns.net:8888/api/v1/AmzVoucherHistory`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info[0];
            })
            .catch((error) => {
                return error;
            });
    }
    savevoucher(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/add_all_vouchers.php`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    savevoucherdealz(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/add_all_vouchers_deal.php`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    saveorders(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/add_all_orders.php`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    getVoucherHistory(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/get-history.php`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                return error;
            });
    }
    getVoucherHistoryDealz(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/get-history-deal.php`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                return error;
            });
    }
    getTicketOrdersHistory(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            //  url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/get-Orders.php`,
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/get-ticket-Orders.php`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                return error;
            });
    }
    getOrdersHistory(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            //  url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/get-Orders.php`,
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/get-Orders.php`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }
    getOrdersHistoryexport(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            //  url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/get-Orders.php`,
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/get-Orders_export.php`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }
    getWinnerHistory(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/get-winners.php`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }
    getSimList(tableItem) {
        let httpmethod = 'get';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: `https://paykart2.ddns.net:8888/api/v1/deviceinfo`,
            method: httpmethod
        };

        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                return error;
            });
    }


    getDashboardsucessfailure(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: `https://paykart2.ddns.net:8888/api/v1/Dashboardsucessfailure`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info[0];
            })
            .catch((error) => {
                return error;
            });
    }

    getCustomers(tableItem) {
        let httpmethod = 'get';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: `https://paykart2.ddns.net:8888/api/v1/ListCustomers`,
            method: httpmethod
        };

        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                return error;
            });
    }

    getBalanceDetails(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: `https://paykart2.ddns.net:8888/api/v1/balancedetails`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data;

            })
            .catch((error) => {
                return error;

            });
    }

    getBalanceDetailsAll(tableItems) {

        let axios_arr = [];
        let res_arr = [];
        let error_arr = [];
        for (var i = 0; i < tableItems.length; i++) {
            let httpmethod = 'post';
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItems[i].token

            let options = {};
            res_arr.push('data_' + i);
            error_arr.push('err_' + i);
            options = {
                url: `https://paykart2.ddns.net:8888/api/v1/balancedetails`,
                data: tableItems[i],
                method: httpmethod
            };
            axios_arr.push(axios(options));
        }
        return axios.all(axios_arr)
            .then(axios.spread((...res) => {
                // output of req.
                return res;
            }))
            .catch(axios.spread((...err) => {
                // output of req.
                return err;
            }));
        // return axios(options)
        //     .then((response) => {
        //         return response.data;

        //     })
        // .catch((error) => {
        //     return error;

        // });
    }

    getUsers(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token;
        let options = {};
        if (tableItem instanceof FormData) {
            options = {
                url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/get-users.php`,
                method: httpmethod,
                data: tableItem,
                headers: { "content-type": "multipart/form-data;" },
            };
        } else {
            options = {
                url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/get-users.php`,
                method: httpmethod,
                data: tableItem,
            };
        }

        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                return error;
            });
    }

    simplerecharge(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token;
        let options = {};

        options = {
            url: `https://paykart2.ddns.net:8888/api/v1/simplerecharge`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }
    geteditdeviceinfo(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://paykart2.ddns.net:8888/api/v1/editdeviceinfo`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }

    addCustPayment(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://paykart2.ddns.net:8888/api/v1/addCustPayment`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }

    getedituserinfo(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/getedituserinfo.php`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }


    getpasschange(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/change-password.php`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }
    getManualRecharge(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://paykart2.ddns.net:8888/api/v1/getManualRecharge`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                // console.log(error)
                // let data = { msg: error.message }
                return '';
            });
    }
    geteditorderinfo(tableItem) {

        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token
        delete tableItem["token"];
        let options = {};

        options = {
            url: `https://paykart2.ddns.net:8888/api/v1/editorderinfo`,
            method: httpmethod,
            data: tableItem
        };

        return axios(options)
            .then((response) => {
                return response.data.info;
            })
            .catch((error) => {
                // console.log(error)
                let data = { msg: error.message }
                return data;
            });
    }

    getDashboardSummary(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: `https://paykart2.ddns.net:8888/api/v1/DashboardSummary`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info[0][0];
            })
            .catch((error) => {
                return error;
            });
    }

    getDashboardSummaryMonthly(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: `https://paykart2.ddns.net:8888/api/v1/DashboardSummaryMonthly`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info[0];
            })
            .catch((error) => {
                return error;
            });
    }

    getDashboardSummaryHourly(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: `https://paykart2.ddns.net:8888/api/v1/DashboardSummaryHourly`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info[0];
            })
            .catch((error) => {
                return error;
            });
    }

    getRecentTrans(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: `https://paykart2.ddns.net:8888/api/v1/RecentTrans`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info[0];
            })
            .catch((error) => {
                return error;
            });
    }

    getPaymentHistory(tableItem) {
        let httpmethod = 'post';
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tableItem.token

        let options = {};

        options = {
            url: `https://paykart2.ddns.net:8888/api/v1/getPaymentHistory`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                return response.data.info[0];
            })
            .catch((error) => {
                return error;
            });
    }

}