import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";
import { AppLogin } from "./AppLogin";
import { OtpVerify } from "./OtpLogin";

import { MainIndex } from "./components/MainIndex";
import { Home } from "./components/home";
import { Dashboard } from "./components/Dashboard";

import {Vouchers} from "./components/Vouchers";
import {Dailydealz} from "./components/Dailydealz"
import {Orders} from "./components/OrdersCode";
import {LuckyDraw } from "./components/LuckyDraw";

import {Users} from "./components/users"

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import "./App.scss";
import { useSelector } from "react-redux";

const App = () => {
    const { isLoggedIn } = useSelector((state) => state.auth);

    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("dark");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    // const rightmenu = false;
    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
        // if (isLoggedIn) {
        //     alert("Please");
        //     return <Redirect to="/" />;
        // } else {
        //     alert("not");
        //     // return <Redirect to="/login" />;
        //     return <Route path="/login" exact component={Dashboard} />
        // }
    }, [mobileMenuActive]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const menu = [
        {
            label: "Home",
            items: [
                {
                    label: "Winner Collection",
                    icon: "pi pi-fw pi-home",
                    to: "/"
                },
                
                {
                    label: "Daily Dealz",
                    icon: "pi pi-fw pi-shopping-bag",
                    to: "/dailydealz"
                },
                // ,{
                //     label: "Lucky Draw",
                //     icon: "pi pi-fw pi-id-card",
                //     to: "/luckydraw"
                // },
                ,{
                    label: "Vouchers",
                    icon: "pi pi-fw pi-id-card",
                    to: "/vouchers"
                },
                
                {
                    label: "Users",
                    icon: "pi pi-fw pi-users",
                    to: "/users"
                }
                
            ]
        }
       
    ];

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });
    // const loginClass = () => {
    //     if (!mobileMenuActive) return "ml-8 pl-6";
    //     else return "m-0 p-0";
    // };

    const styleObj = {
        opacity: 0,
    };
    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

            <div className="layout-sidebar" style={!isLoggedIn ? styleObj : {}} onClick={onSidebarClick}>
                <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
            </div>

            <div className="layout-main-container">
                <div className="layout-main">
                    {/* <Route path="/" exact component={Dashboard} /> */}
                    <Route path="/" exact component={Home} />
                    {/* <Route path="/" exact component={MainIndex} /> */}
                    {/* <Route path="/formlayout" component={FormLayoutDemo} />
                    <Route path="/input" component={InputDemo} />
                    <Route path="/floatlabel" component={FloatLabelDemo} />
                    <Route path="/invalidstate" component={InvalidStateDemo} />
                    <Route path="/button" component={ButtonDemo} />
                    <Route path="/table" component={TableDemo} />
                    <Route path="/list" component={ListDemo} />
                    <Route path="/tree" component={TreeDemo} />
                    <Route path="/panel" component={PanelDemo} />
                    <Route path="/overlay" component={OverlayDemo} />
                    <Route path="/menu" component={MenuDemo} />
                    <Route path="/messages" component={MessagesDemo} />
                    <Route path="/blocks" component={BlocksDemo} />
                    <Route path="/icons" component={IconsDemo} />
                    <Route path="/file" component={FileDemo} />
                    <Route path="/chart" component={ChartDemo} />
                    <Route path="/misc" component={MiscDemo} />
                    <Route path="/timeline" component={TimelineDemo} />
                    <Route path="/crud" component={Crud} />
                    <Route path="/empty" component={EmptyPage} />
                    <Route path="/baltransfer" component={BalanceTransfer} />
                    <Route path="/recharge" component={Recharge} />
                    <Route path="/documentation" component={Documentation} />
                    <Route path="/history" component={History} />
                    <Route path="/simlist" component={Simlist} /> */}
                    <Route path="/users" component={Users} />
                    {/* <Route path="/simbalance" component={SimBalance} /> */}
                    {/* <Route path="/payments" component={Payments} /> */}
                    {/* <Route path="/amzvoucher" component={AmzVoucher} /> */}
                    <Route path="/vouchers" component={Vouchers} />
                    <Route path="/dailydealz" component={Dailydealz} />
                    {/* <Route path="/orders" component={Orders} /> */}
                    {/* <Route path="/luckydraw" component={LuckyDraw} /> */}
                    <div className={ !isDesktop() ? "" : "ml-8 pl-8"}>
                        <Route path="/login" component={AppLogin} />
                        <Route path="/otpverify" component={OtpVerify} />
                    </div>
                </div>
                {/* <AppLogin onLayoutModeChange={onLayoutModeChange} /> */}
                {/* {!isLoggedIn && (
                    <div className="ml-8 pl-6">
                        <div className="flex align-items-center m-8">
                            <Route path="/login" component={AppLogin} />
                        </div>
                    </div>
                )}  */}

                <AppFooter layoutColorMode={layoutColorMode} />
            </div>

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>
        </div>
    );
};
export default App;
