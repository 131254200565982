import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import classNames from "classnames";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { CustomerService } from "../service/CustomerService";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";

export const Vouchers = () => {
    const [displayBasic, setDisplayBasic] = useState(false);
    const basicDialogFooter = <Button type="button" label="Dismiss" onClick={() => setDisplayBasic(false)} icon="pi pi-check" className="p-button-secondary" />;
    const statustype = [
        { id: "1", name: "Valid" },
        { id: "0", name: "In-Valid" },
    ];
    const toast = useRef(null);
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const [historynew, setHistorynew] = useState(null);
    const [singelrowData, setSingelrowData] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [filters1, setFilters1] = useState(null);
    const [passwordDialog, setPasswordDialog] = useState(false);
    const [value8, setValue8] = useState({ id: null, name: null });
    const [id, setID] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState("");
    const [loading1, setLoading1] = useState(true);
    const [response, setResponse] = useState(true);
    const [portNo, setportNo] = useState(0);
    const [passsubmitted,setPassSubmitted] = useState(false);
    const [startdate, setStartdate] = useState(new Date((Date.now() - (15*24*60*60*1000))- new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10));
    const [enddate, setEnddate] = useState(new Date(Date.now()  - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10));
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [displayConfirmationdelete, setDisplayConfirmationdelete] = useState(false);
    const [rowData, setrowData] = useState("")
    const onChangeStartdate = (e) => {
        const startdate = e.target.value;
        setStartdate(startdate);
    };
    const reject = () => {
        setDisplayConfirmation(false)
        // toast.current.show({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 5000 });
    };
    const rejectdelete = () => {
        setDisplayConfirmationdelete(false)
        // toast.current.show({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 5000 });
    };
    const confirmationDialogFooter = (
        <>
            <Button type="button" label="No" icon="pi pi-times" onClick={() => reject()} className="p-button-text" />
            <Button type="button" label="Yes" icon="pi pi-check" onClick={() => accept()} className="p-button-text" autoFocus />
        </>
    );
    const confirmationDialogFooterdelete = (
        <>
            <Button type="button" label="No" icon="pi pi-times" onClick={() => rejectdelete()} className="p-button-text" />
            <Button type="button" label="Yes" icon="pi pi-check" onClick={() => acceptdelete()} className="p-button-text" autoFocus />
        </>
    );
    const acceptdelete = () => {
        setDisplayConfirmationdelete(false)
        let tableItem = {};
        tableItem.token = user.token;
        tableItem.role = user.user.role;
        tableItem.id = user.user.id;
        tableItem.products_id = rowData.id;
        tableItem.voucher_id = rowData.code;
            
        if (rowData.status == "0") {
            tableItem.status = 1;
        }
        else{
            tableItem.status = 0;
        }
        customerService.getDeleteStatus(tableItem).then((data) => {
           if(data.status === "0")
           {
            toast.current.show({ severity: 'error', summary: 'Failed', detail: data.message, life: 5000 });
           }
           else{
            toast.current.show({ severity: "success", summary: "Success", detail: data.message, life: 5000 });
            
           }
           searchnewlist();
        });
    };
    const accept = () => {
        setDisplayConfirmation(false)
        let tableItem = {};
        tableItem.token = user.token;
        tableItem.role = user.user.role;
        tableItem.id = user.user.id;
        tableItem.products_id = rowData.id;
        tableItem.voucher_id = rowData.code;
            
        if (rowData.status == "0") {
            tableItem.status = 1;
        }
        else{
            tableItem.status = 0;
        }
        customerService.getChangeStatus(tableItem).then((data) => {
           if(data.status === "0")
           {
            toast.current.show({ severity: 'error', summary: 'Failed', detail: data.message, life: 5000 });
           }
           else{
            toast.current.show({ severity: "success", summary: "Success", detail: data.message, life: 5000 });
            
           }
           searchnewlist();
        });
    };
    const hidePassDialog= () => {
        setPassSubmitted(false);
        setPasswordDialog(false);
    };
     const saveRechargeManually = () => {
        setPassSubmitted(true);
        console.log(portNo)
        if (!portNo || portNo == 0) {
            toast.current.show({ severity: "error", summary: "Port Number.", detail: "Port Number can't be empty.", life: 3000 });
        }
       
       
        else
        {
         const customerService = new CustomerService();
        let tableItem = {};
        tableItem.portNo = portNo;
       
        tableItem.token = user.token;
        tableItem.id = id;

            // console.log(tableItem)
        customerService.getManualRecharge(tableItem).then((data) => {
            if(data)
                toast.current.show({ severity: "success", summary: "Successful", detail: "Recharge Successfully", life: 3000 });
            else
                toast.current.show({ severity: "error", summary: "Failed", detail: "Something went wrong!", life: 3000 });
        }).catch((error) => {
            toast.current.show({ severity: "error", summary: "Failed", detail: "Something went wrong!", life: 3000 });
        });
        setPassSubmitted(false);
        setPasswordDialog(false);
        }
    }
    const onUpload = (e) => {
        readFileContent(e.files[0]).then((textFile) => {
            textFile = textFile.replace(/\r/g, "");
            const headers = textFile.slice(0, textFile.indexOf("\n")).split(",");
            // console.log(headers);
            // const rows = textFile.slice(textFile.indexOf("\n") + 1).split("\n");
            let column_name = ['first_name','last_name','code','amount'];
            if (headers.length > column_name.length) {
                toast.current.show({ severity: "error", summary: "Failed", detail: "Column Headers Not Same.", life: 5000 });
                return false;
            }
            for (let i = 0; i < column_name.length; i++) {
                if (!headers.includes(column_name[i])) {
                    toast.current.show({ severity: "error", summary: "Failed", detail: "Column Headers Not Same.", life: 5000 });
                    return false;
                }
            }
            submitFile(e.files[0]);
        });
        //  toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Something went wrong.Please try agian later or with differnt file.', life: 5000 });
    };
    const readFileContent = (file) => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onload = (event) => resolve(event.target.result);
            reader.onerror = (error) => reject(error);
            reader.readAsText(file);
        });
    };

    const submitFile = (file) => {
        let tableItem = new FormData();
        tableItem.token = user.token;
        //tableItem.id = user.id;
        tableItem.append("id", user.user.id);
        tableItem.append("file", file, file.name);
        customerService
            .savevoucher(tableItem)
            .then((data) => {
                searchnewlist()
                toast.current.show({ severity: "info", summary: "Response", detail: data.msg, life: 5000 });
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: "Failed", detail: "Something went wrong!", life: 5000 });
            });
    };

    const exportCSV = () => {
        // Headers for each column
        let headers = ["first_name,last_name,code,amount,status,created_at"];

        // Convert users data to a csv

        let usersCsv = historynew.reduce((acc, detail) => {
            if (detail.status == "0") {
                detail.status = "inactive";
                // var str = detail.date.toLocaleString("en-US", { timeZone: "Asia/Dubai" });
                // detail.date = str.replace(/,/, " ");
            } else if (detail.status == "1") {
                detail.status = "active";
                // var str2 = detail.date.toLocaleString("en-US", { timeZone: "Asia/Dubai" });
                // detail.date = str2.replace(/,/, " ");
            }
            // else {
            //     detail.status = "Pending";
            //     var str1 = detail.date.toLocaleString("en-US", { timeZone: "Asia/Dubai" });
            //     detail.date = str1.replace(/,/, " ");
            // }
            detail.created_at = new Date(detail.created_at);
            var str1 = detail.created_at.toLocaleString("en-GB", { timeZone: "Asia/Dubai" });
            detail.created_at = str1.replace(/,/, " ");
            
            const { first_name,last_name,code,amount,status,created_at } = detail;
            acc.push([first_name,last_name,code,amount,status,created_at].join(","));
            return acc;
        }, []);

        downloadFile({
            data: [...headers, ...usersCsv].join("\n"),
            fileName: "Voucher_history.csv",
            fileType: "text/csv",
        });
    };
    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType });

        const a = document.createElement("a");
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
        });
        a.dispatchEvent(clickEvt);
        a.remove();
    };

    const onChangeEnddate = (e) => {
        const enddate = e.target.value;
        setEnddate(enddate);
    };

    const statuses = ["0", "1"];
    // let startdate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    // let enddate = (new Date(Date.now() - new Date().getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    const customerService = new CustomerService();

    useEffect(() => {
        if (user) {
            setLoading1(true);

            let tableItem = {};
            tableItem.token = user.token;
            tableItem.role = user.user.role;
            tableItem.id = user.user.id;
            tableItem.date = startdate;
            tableItem.enddate = enddate;
            // console.log(tableItem);
            customerService.getVoucherHistory(tableItem).then((data) => {
                if (isIterable(data)) {
                    setHistorynew(getCustomersnew(data));
                }
                setLoading1(false);
            });

            initFilters1();
        }
    }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

    const isIterable = (obj) => {
        // checks for null and undefined
        if (obj == null) {
            return false;
        }
        return typeof obj[Symbol.iterator] === "function";
    };
    const passwordDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hidePassDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveRechargeManually} />
            {/* <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveRechargeManually} /> */}
        </>
    );
    const hideDialog = () => {
        // setSubmitted(false);
        setProductDialog(false);
    };
    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < historynew.length; i++) {
            if (historynew[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };
    const saveProduct = () => {
        if (!value8.id) {
            toast.current.show({ severity: "error", summary: "Please select status.", detail: "Status can't be empty.", life: 3000 });
        }
        const customerService = new CustomerService();

        if (value8.id && singelrowData.id) {
            let _products = [...historynew];
            let _product = { ...singelrowData };
            let tableItem = {};
            tableItem.id = _product.id;
            tableItem.status = value8.id;
            tableItem.token = user.token;
            customerService
                .geteditorderinfo(tableItem)
                .then((data) => {
                    setProductDialog(false);
                    const index = findIndexById(_product.id);
                    // alert(_product.status);
                    _product.status = value8.id;
                    // alert(_product.status);
                    _products[index] = _product;

                    toast.current.show({ severity: "success", summary: "Successful", detail: "Order Updated", life: 3000 });
                })
                .catch((error) => {
                    setProductDialog(false);
                    toast.current.show({ severity: "error", summary: "Failed", detail: "Something went wrong!", life: 3000 });
                });
            setHistorynew(_products);
            setProductDialog(false);
            setLoading1(true);
            searchnewlist();
        }
    };
    const searchnewlist = () => {
        if (user) {
            let date1 = new Date(startdate);
            let date2 = new Date(enddate);

            if (date2 < date1) {
                toast.current.show({ severity: "error", summary: "End Date Should Always Greater Or Equal To Start Date!", detail: "", life: 5000 });
                return false;
            }

            setLoading1(true);

            let tableItem = {};
            tableItem.token = user.token;
            tableItem.role = user.user.role;
            tableItem.id = user.user.id;
            tableItem.date = startdate;
            tableItem.enddate = enddate;
            customerService.getVoucherHistory(tableItem).then((data) => {
                setHistorynew(getCustomersnew(data));
                setLoading1(false);
            });

            initFilters1();
        }
    };
   
    const getCustomersnew = (data) => {
        if (data) {
            return [...(data || [])].map((d) => {
                d.date = new Date(d.created_date);
                // if (d.modified_date) {
                //     d.modified_date = new Date(d.modified_date);
                // }
                // d.mask_voucher = getstarred(d.voucher_number);
                if ( d.status === 1) {
                    d.status = "1";
                } else {
                    d.status = "0";
                }
                return d;
            });
        }
    };
    const getstarred = (str) => {
        let len = str.length;

        return str.substr(0, 4) + "*".repeat(len - 8) + str.substr(len - 4, 4);
    };
    const formatDate = (value) => {
        var dateA = new Date(value);
        var str = dateA.toLocaleString("en-US", { timeZone: "Asia/Dubai" });
        return str.replace(/,/, " ");
    };

    const formatCurrency = (value) => {
        return value.toLocaleString("en-US", { style: "currency", currency: "AED" });
    };
    const formatdata = (value) => {
        if (!value) {
            return <span className={`customer-badge status-unqualified`}>Not-Used</span>;
        } else {
            return <span className={`customer-badge status-qualified`}>{value}</span>;
        }
    };

    const clearFilter1 = () => {
        initFilters1();
    };

    const  RechargeManually= (rowdata) => {
        setID(rowdata.id);
        setPasswordDialog(true);
    };
    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1["global"].value = value;

        setFilters1(_filters1);
        // console.log(value);
        setGlobalFilterValue1(value);
    };

    const initFilters1 = () => {
        setFilters1({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            order_number: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            serial: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            mask_voucher: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            denomination: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            used_by: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            agent_ID: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            modified_date: { operator: FilterOperator.AND, constraints: [{ value: null, matchModematchMode: FilterMatchMode.DATE_IS }] },
            status: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            // order_id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        });
        setGlobalFilterValue1("");
    };

    const headerTemplate = (data) => {
        return (
            <React.Fragment>
                <span style={{ textAlign: "center", color: "purple" }} className="text-bold pr-6">
                    <b>Total Active :-{calculateTotalrecharges(historynew)}</b>
                </span>
                <span style={{ textAlign: "center", color: "green" }} className="text-bold pr-6">
                    <b>Total Inactive  :-{calculateTotalsuccess(historynew)}</b>
                </span>
            </React.Fragment>
        );
    };

    const calculateTotalsuccess = (historynew) => {
        let total = 0;
        if (historynew) {
            for (let data of historynew) {
                if (data.status != '1') {
                    total++;
                }
            }
        }
        return 'Total Inactive :-' +total;
    };
    const calculateTotalrecharges = (historynew) => {
        let total = 0;
        if (historynew) {
            for (let data of historynew) {
                if (data.status == '1') {
                    total++;
                }
            }
        }
        return 'Total Active :-' +total;
    };
    const renderHeader1 = () => {
        return (
            <div>
                <div className="flex justify-content-between  card border-1 surface-border  flex-column sm:flex-row mb-2">
                    <Calendar value={startdate} dateFormat="yy-mm-dd" className="p-button-outlined mb-2" onChange={onChangeStartdate} placeholder="yyyy-mm-dd" mask="9999-99-99" />
                    <Calendar value={enddate} dateFormat="yy-mm-dd" className="p-button-outlined mb-2" onChange={onChangeEnddate} placeholder="yyyy-mm-dd" mask="9999-99-99" />
                    <Button type="button" icon="pi pi-search" label="Search" className="p-button-outlined mb-2" onClick={searchnewlist} />
                    <Button label="Export" icon="pi pi-upload" className="p-button-help " onClick={exportCSV} />
                </div>
                <div className="flex justify-content-between flex-column sm:flex-row ">
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined mb-2" onClick={clearFilter1} />
                    <Button type="button" icon="pi pi-refresh" label="Refresh" className="p-button-outlined mb-2" onClick={searchnewlist} />

                    <span className="p-input-icon-left mb-2">
                        <i className="pi pi-search" />
                        <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Keyword Search" style={{ width: "100%" }} />
                    </span>
                </div>
            </div>
        );
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    };
    const onPortNoChange = (e) => {
        const val = (e.target && e.target.value) || "";
        setportNo(val);
    };
    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yyyy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };
    const useddateBodyTemplate = (rowData) => {
        if (rowData.created_at) return formatDate(rowData.created_at);
        else return rowData.created_at;
    };
    const nameBodyTemplate = (rowData) => {
         return rowData.first_name + ' ' + rowData.last_name;
    };
    const useddateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yyyy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    const balanceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.denomination);
    };
    const usedbyBodyTemplate = (rowData) => {
        return formatdata(rowData.used_by);
    };
    const agentidBodyTemplate = (rowData) => {
        return formatdata(rowData.agent_ID);
    };
    const balanceFilterTemplate = (options) => {
        return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} mode="currency" currency="AED" locale="en-US" />;
    };
    const usedbyFilterTemplate = (options) => {
        return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} />;
    };
    const agentidFilterTemplate = (options) => {
        return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} />;
    };
    const statusBodyTemplate = (rowData) => {
        if (rowData.status == "0") {
            return (
                  <div className="actions">
                  <Button type="button" label="In-Active" className="mr-2 p-button-danger" onClick={() => editProduct(rowData)}></Button>
                  <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => editProductdelete(rowData)} />
              </div>
            );
        } else  {
            return (
               
                <div className="actions">
                    <Button type="button" label="Active" className="mr-2 p-button-success" onClick={() => editProduct(rowData)}></Button>
                    <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => editProductdelete(rowData)} />
              </div>
                
            );
        } 
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;
    };

    const statusItemTemplate = (option) => {
        if (option === "0") {
            return <span className={`customer-badge status-unqualified`}>In-Valid</span>;
        } else  {
            
                return <span className={`customer-badge status-qualified`}>Valid</span>;
            
        }
    };
    const editProduct = (rowData) => {
        setDisplayConfirmation(true)
        setrowData(rowData);
    };
    const editProductdelete = (rowData) => {
        setDisplayConfirmationdelete(true)
        setrowData(rowData);
    };
    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const header1 = renderHeader1();
    if (!isLoggedIn) {
        return <Redirect to="/" />;
    }
    return (
        <div className="grid table-demo">
            <div className="col-12">
                <div className="card">
                    <h5>
                        Upload Voucher{" "}
                        <a href="./csv-sample.csv" download>
                            (Sample CSV)
                        </a>
                    </h5>
                    <FileUpload customUpload={true} multiple={false} name="demo[]" uploadHandler={onUpload} accept=".csv,.xlsx,.xls" maxFileSize={1000000} />

                    {/* <h5>Basic</h5>
                    <FileUpload mode="basic" customUpload="true" name="demo[]"  accept="*" maxFileSize={1000000} uploadHandler={onUpload} /> */}
                </div>
            </div>
            <Toast ref={toast} />
            <Dialog header="Message" visible={displayBasic} style={{ width: "450px" }} className="p-fluid" modal footer={basicDialogFooter} onHide={() => setDisplayBasic(false)}>
                <p>{response}</p>
            </Dialog>
            <Dialog visible={productDialog} style={{ width: "450px" }} header="Status Info" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                <div className="formgrid grid">
                    <div className="col-12 col">
                        {/* <label htmlFor="dropdown">Status</label> */}
                        <Dropdown id="dropdown" options={statustype} value={value8} onChange={(e) => setValue8(e.value)} optionLabel="name" placeholder="Select Status" className="p-column-filter"></Dropdown>
                    </div>
                </div>
            </Dialog>
            <Dialog header="Confirmation" visible={displayConfirmation} onHide={() => setDisplayConfirmation(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooter}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                <span>Are you sure you want to Change Status?</span>
                            </div>
                        </Dialog>
                        <Dialog header="Confirmation" visible={displayConfirmationdelete} onHide={() => setDisplayConfirmationdelete(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooterdelete}>
                            <div className="flex align-items-center justify-content-center">
                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                <span>Are you sure you want to Delete?</span>
                            </div>
                        </Dialog>
            <Dialog visible={passwordDialog} style={{ width: "450px" }} header="Recharge Manually" modal className="p-fluid" footer={passwordDialogFooter} onHide={hidePassDialog}>
                        
                        <div className="field">
                            <label htmlFor="name">Port Number</label>
                            <InputText id="name" value={portNo} onChange={(e) => onPortNoChange(e)} required autoFocus className={classNames({ "p-invalid": passsubmitted && !portNo })} />
                            {passsubmitted && !portNo && <small className="p-invalid">Port NO. is required.</small>}
                        </div>
                       
                    </Dialog>
            <div className="col-12">
                <div className="card">
                    <h5>Vouchers List</h5>
                    <div className="formgrid grid">
                        <div className="col-12 col">
                            <div className="flex align-items-center flex-column sm:flex-row">
                                <Button type="button" label={calculateTotalsuccess(historynew)} className="mr-2"></Button>
                                <Button type="button" label={calculateTotalrecharges(historynew)} className="mr-2 p-button-warning"></Button>
                    
                            </div>
                        </div>
                    </div>

                    <DataTable
                        // ref={dt}
                        value={historynew}
                        paginator
                        className="p-datatable-gridlines datatable-responsive"
                        showGridlines
                        rows={15}
                        // rowGroupMode="header"
                        // rowGroupHeaderTemplate={headerTemplate}
                        rowsPerPageOptions={[10, 15, 50]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                        sortMode="single"
                        sortField={["order_number", "serial", "denomination", , "modified_date", "mask_voucher", "used_by", "agent_ID", "date"]}
                        sortOrder={1}
                        dataKey="id"
                        filters={filters1}
                        filterDisplay="menu"
                        loading={loading1}
                        globalFilterFields={["first_name", "last_name", "code","amount" , "created_At", ]}
                        header={header1}
                        emptyMessage="No History found."
                    >
                        <Column sortable field="first_name" header="Name" filter body={nameBodyTemplate} filterPlaceholder="Search by name" />
                        {/* <Column sortable field="serial" header="Sl No." filter filterPlaceholder="Search by Sl No." /> */}
                        <Column sortable field="code" header="Code" filter filterPlaceholder="Search by code number" />
                        {/* <Column sortable header="Date" filterField="date" dataType="date" body={dateBodyTemplate} filter filterElement={dateFilterTemplate} /> */}
                        <Column sortable header="Created Date" filterField="created_at" dataType="date" body={useddateBodyTemplate} filter filterElement={useddateFilterTemplate} />
                        <Column sortable field="amount" header="Amount" filter filterPlaceholder="Search by Amount" />
                        {/* <Column sortable header="Used By" filterField="used_by" dataType="numeric" body={usedbyBodyTemplate} filter filterElement={usedbyFilterTemplate} /> */}
                        {/* <Column header="Agent ID" filterField="agent_ID" dataType="numeric" body={agentidBodyTemplate} filter filterElement={agentidFilterTemplate} /> */}
                        <Column sortable field="status" header="status" filterMenuStyle={{ width: "14rem" }} body={statusBodyTemplate} filter filterElement={statusFilterTemplate} />
                        {/* <Column sortable header="Order Id" field="order_id" filter filterPlaceholder="Search by Order. Id" /> */}
                        {/* <Column body={actionBodyTemplate}></Column> */}
                    </DataTable>
                </div>
            </div>
        </div>
    );
};
