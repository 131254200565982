import React, { useState, useRef } from "react";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { login } from "./redux/actions";
import Form from "react-validation/build/form";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
export const AppLogin = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { sentotp } = useSelector((state) => state.auth);
    const { isLoggedIn } = useSelector((state) => state.auth);

    // const { message } = useSelector((state) => state.msg);
    const toast = useRef(null);
    // const form = useRef();
    // setTimeout(function () {
    //     // alert('Reloading Page');
    //     window.location.reload(false);
    //   }, 1000);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };
    const required = (value, name) => {
        if (!value) {
            toast.current.show({ severity: "error", summary: "This field is required!", detail: name, life: 5000 });
            return false;
        }
        return true;
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleLogin = (e) => {
        e.preventDefault();

        setLoading(true);
        if (!required(username, "username") || !required(password, "password")) {
            setLoading(false);
            return false;
        }

        // form.current.validateAll();

        dispatch(login(username, password))
            .then(() => {
                //   props.history.push("/profile");
                //   window.location.reload();
                setLoading(false);
            })
            .catch((error) => {
                 

                setLoading(false);
            });
    };
    // if (sentotp) {
    //     toast.current.show({ severity: 'success', summary: 'Successful',detail: 'OTP sent !', life: 5000 });
    //    return <Redirect to="/otpverify" />;
    // }
    if (isLoggedIn) {
        return <Redirect to="/" />;
    }
    return (
        <div className="justify-content-center surface-card p-4 shadow-2 border-round w-full lg:w-6">
            <Toast ref={toast} />
            <div className="text-center mb-5">
                <img src="images/blocks/logos/white-logo.png" alt="hyper" height="70" className="mb-3" />
                <div className="text-900 text-3xl font-medium mb-3">Welcome Back</div>
            </div>
            <Form >
            <div>
                <label htmlFor="user1" className="block text-900 font-medium mb-2">
                    UserName
                </label>
                <InputText id="user1" type="text" name="username" value={username} validations={[required]} onChange={onChangeUsername} className="w-full mb-3" />

                <label htmlFor="password1" className="block text-900 font-medium mb-2">
                    Password
                </label>
                <InputText id="password1" type="password" name="password" value={password} validations={[required]} onChange={onChangePassword} className="w-full mb-3" />

                <Button type="submit" label="Sign In" icon={!loading ? "pi pi-user" : "pi pi-spin pi-spinner"} className="w-full" disabled={loading} onClick={handleLogin} />
            </div>
            </Form>
        </div>
    );
};
