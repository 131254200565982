import React, { useState, useRef } from "react";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Form from "react-validation/build/form";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { otpverfiy } from "./redux/actions/authActions";
export const OtpVerify = () => {
    const [otp, setOtp] = useState("");
    const { isLoggedIn, user } = useSelector((state) => state.auth);
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const onChangeOtp = (e) => {
        const otp1 = e.target.value;
        setOtp(otp1);
    };
    const required = (value, name) => {
        if (!value) {
            toast.current.show({ severity: "error", summary: "This field is required!", detail: name, life: 5000 });
            return false;
        }
        return true;
    };

   

    const handleLogin = (e) => {
        e.preventDefault();

        setLoading(true);
        if (!required(otp, "otp")) {
            setLoading(false);
            return false;
        }

      
        dispatch(otpverfiy(user.user.usid, otp))
            .then(() => {
                //   props.history.push("/profile");
                //   window.location.reload();
                setLoading(false);
            })
            .catch((error) => {
                toast.current.show({ severity: "error", summary: error, detail: "", life: 5000 });

                setLoading(false);
            });
    };
    if (isLoggedIn) {
        return <Redirect to="/" />;
    }
    return (
        <div className="justify-content-center surface-card p-4 shadow-2 border-round w-full lg:w-6">
            <Toast ref={toast} />
            <div className="text-center mb-5">
                <img src="images/blocks/logos/white-logo.png" alt="hyper" height="70" className="mb-3" />
                <div className="text-900 text-3xl font-medium mb-3">OTP Verification</div>
            </div>
            <Form >
            <div>
                <label htmlFor="user1" className="block text-900 font-medium mb-2">
                    OTP
                </label>
                <InputText id="user1" type="text" name="otp" value={otp} validations={[required]} onChange={onChangeOtp} className="w-full mb-3" />

              
                <Button type="submit" label="Sign In" icon={!loading ? "pi pi-user" : "pi pi-spin pi-spinner"} className="w-full" disabled={loading} onClick={handleLogin} />
            </div>
            </Form>
        </div>
    );
};
