import axios from "axios";

// axios.defaults.baseURL = 'https://paykart.ddns.net:5001/api/'



const login = (username, password) => {
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.get['Accept'] = 'application/json'
    const headers = Buffer.from(username + ":" + password, "utf8").toString("base64");

    return axios
        .get("https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/login-api.php", { headers: { Authorization: "Basic " + headers } })
        .then((response) => {
            //if (response.data.token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
            return response.data;
        });
};

const otpverfiy = (user_id, otp) => {
        let httpmethod = 'post';
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.get['Accept'] = 'application/json'

        let options = {};
        let tableItem = {};
        tableItem.otp = otp;
        tableItem.id = user_id;
        options = {
            url: `https://coupons.dealzarabia.com/coupons_dealzarabia_api/v1/login-otp-api.php`,
            method: httpmethod,
            data: tableItem,
        };

        return axios(options)
            .then((response) => {
                if (response.data.token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
                return response.data;
            })
            .catch((error) => {
                return error;
            });
};

const logout = () => {
    localStorage.removeItem("user");
};

export default {
    login,
    otpverfiy,
    logout,
};